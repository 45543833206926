<template>
  <div></div>
</template>
<script>
export default {
  name: 'hujiang',
  mounted() {
    var params = window.location.href.split('?')[1]
    var type = this.$route.query.type
    if(type==1) { // 1本地, 2测试, 3预发, 4正式
      window.location.href = 'http://dev.kaoyan-vip.cn:8082/user/#/?' + params
    } else if(type==2) {
      window.location.href = 'https://crs2.kaoyan-vip.cn/user/#/?' + params
    } else if(type==3) {
      window.location.href = 'https://crs2.kaoyan-vip.com/user/#/?' + params
    } else if(type==4) {
      window.location.href = 'https://crs2.kaoyanvip.cn/user/#/?' + params
    }
  }
}
</script>
